#navbar .navbar {
    padding: 2rem 2.085vw !important; /* padding: 32px 40px;*/
}
#navbar .navbar .container {
    max-width: 1920px;
    padding: 0 !important;
}
#navbar .navbar-brand h1 {
    font-weight: 600;
    text-transform: uppercase;
    margin: 0 !important;
    padding: 0 !important;
}
#navbar .navbar-nav {
    width: 46.3vw;
    max-width: 966px;
    line-height: 0 !important;
    justify-content: space-between;
}
#navbar .nav-link,
.navbar-brand {
    padding: 0 !important;
}
#navbar .nav-link .nav-btn {
    color: var(--font-color-primary);
    border-radius: 10px;
    font-weight: 400;
    padding: 0 0.5vw 0.2vw;
    white-space: nowrap;
    background-color: transparent;
}

.nav-link .nav-btn:hover,
.navbar-brand h1:hover {
    background-color: rgb(238, 238, 238) !important;
}
#navbar .h3 {
    font-size: calc(1.2rem + 0.5vw);
    margin: 0 !important;
}

#navbar .nav-link i {
    font-size: 28px;
    line-height: 0 !important;
    position: relative;
    top: 15px;
    left: 1%;
}
#navbar .navbar-text i {
    color: var(--font-color-primary);
    font-size: 28px;
    line-height: 0 !important;
    padding-left: 10px;
    position: relative;
    top: 10px;
    left: 1%;
}

#right-aligned {
    justify-content: flex-end;
}

#navbar .hide {
    display: none;
}
#navbar form .row {
    flex-wrap: nowrap;
}

#navbar .col-auto input {
    width: 100%;
    max-width: 100%;
}
#navbar .col-auto .search {
    margin: 0 0.4vw;
    color: #fff;
    background-color: #003738;
}
#navbar .col-auto .cancel {
    color: #fff;
    background-color: darkred;
}

@media (max-width: 1400px) {
    #navbar .h3 {
        font-size: 1.4rem;
    }
}

@media (max-width: 1200px) {
    #navbar .navbar-nav {
        width: 100%;
        margin: 10px 0 0 10px;
        gap: 10px;
    }
    #navbar form .show {
        margin: 8vh 0 0;
        width: 100%;
        flex-wrap: nowrap;
    }
}

@media (max-width: 576px) {
    #navbar .navbar {
        padding: 1rem 3vw !important;
    }
}

@media (max-width: 350px) {
    #navbar .h1 {
        font-size: calc(1rem + 1.5vw);
    }
}
