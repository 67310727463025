#contactInfo .info-container {
    padding: min(6vw, 80px) 0;
    max-width: 1920px;
    margin: 0 auto;
}
#contactInfo .container {
    border-radius: 32px;
    background-color: #fff;
    max-width: min(94vw, 1160px);
    padding: min(5vw, 100px);
    align-self: stretch;
}
#contactInfo .left-col {
    gap: 2rem !important;
}
.info-description {
    font-size: 1.5rem;
}
#contactInfo a {
    text-decoration: none;
}
.email-us-btn {
    color: #fff !important;
    font-weight: bolder;
    background-color: #00696c;
    height: 45px;
    width: 280px;
    margin-top: -20px;
}
.email-us-btn:hover {
    background-color: #1f8c8f;
}
#contactInfo #btn-show-on-mobile {
    display: none;
}

@media (max-width: 992px) {
    #contactInfo {
        padding-left: 6vw;
        padding-right: 6vw;
    }

    #contactInfo .h3,
    .info-description {
        font-size: 1rem;
    }
    #contactInfo .left-col {
        gap: 0.2rem !important;
    }
    #contactInfo .sustainability-hub-logo img {
        width: min(65vw, 240px) !important;
    }
    .email-us-btn {
        width: 22vw;
        margin-top: 5px;
    }
}

@media (max-width: 768px) {
    #contactInfo .container {
        padding: min(3rem, 10vw);
        max-width: 400px;
    }
    #contactInfo .container .row {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem !important;
    }
    .info-title {
        margin: 0 auto 5px;
    }
    #contactInfo .sustainability-hub-logo img {
        margin: auto !important;
    }

    #contactInfo #btn-dont-show-on-mobile {
        display: none;
    }
    #contactInfo #btn-show-on-mobile {
        display: block;
        width: min(60vw, 240px);
        margin: 2rem auto 0 !important;
    }
    #contactInfo #btn-show-on-mobile button {
        width: min(60vw, 240px);
    }
}
