.pop-modal .modal-fullscreen {
    /* width: 90vw; */
    /* margin: 0 auto !important; */
}
.pop-modal-title {
    color: #000d31;
    max-width: 1720px;
    padding: 0 2.085vw !important;
}
.pop-modal .fw-bold {
    font-weight: 600 !important;
}
.popup-image img {
    max-height: 657px;
    max-width: 630px;
    width: 100%;
    border-radius: 20px;
    display: flex;
}
.event-subHeading {
    max-width: 729px;
    text-align: left;
}

.event-schedule > * {
    max-width: 771px;
    min-height: 81px;
    border-radius: 10px;
    align-content: center;
    padding: 0 2vw;
    margin-bottom: 0 !important;
}
.event-schedule .bg-secondary {
    background-color: #9cd8e9 !important;
}
.project-subHeadings {
    max-width: 736px;
}

.pop-back-button {
    max-width: 170px;
    width: 20vw;
}
@media (min-width: 1400px) {
    .display-6 {
        font-size: min(3vw, 60px);
    }
}
@media (max-width: 992px) {
    .popup-image img {
        margin-bottom: 30px !important;
        margin: 0 auto;
    }
    .event-schedule > * {
        padding: 5px 20px;
    }
}
@media (max-width: 576px) {
    .pop-back-button {
        width: 100px;
    }
}
