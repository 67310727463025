#team {
    background-color: #fff;
    border-top: 1px solid #000;
    /* border-bottom: 1px solid #000; */
    padding: min(4.05vw, 80px) 0;
}

#team .team-container {
    max-width: 1200px;
    padding: 0 !important;
}
#team .team-container .row {
    margin: 0 auto !important;
}
#team .card {
    border: none;
    background-color: transparent;
}

.draw-line {
    width: 228px;
    height: 4px;
    border-radius: 5px;
    flex-shrink: 0;
    background-color: #00696c;
}
#team .my-5 {
    margin: min(3vw, 30px) 0 !important;
}
.title1-row,
.title2-row {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
}

/* Leader.jsx && Researchers.jsx */

.leaders-row,
.researchers-row {
    justify-content: center;
    flex-wrap: wrap;
    padding-top: min(2vw, 24px);
}

.leaders-row1 > *,
.researchers-row > * {
    margin: 0;
    padding: 0;
}

#team .title1-row h1 {
    font-size: 3.8rem !important;
}

.pastResearchers-row .line-text {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.pastResearchers-row .line {
    flex-grow: 1;
    height: 1px;
    background-color: #2e5606;
}

.pastResearchers-row .line-text-content {
    white-space: nowrap;
}

@media (max-width: 1200px) {
    #team .team-container {
        max-width: 92vw;
    }
    .team-container .title1-row .draw-line {
        width: 25vw;
        flex-shrink: initial;
    }
    .team-container .px-5 {
        padding: 0 4vw !important;
    }
}

@media (max-width: 992px) {
    #team .leaders-row {
        max-width: 84vw;
    }
}
