#system-card {
    padding: min(1.25vw, 24px);
    max-width: 29.9rem;
    border: none;
    border-radius: 40px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.system-card-description {
    height: 120px;
}
.system-card-image {
    max-width: 430px !important;
    max-height: 342px !important;
    border-radius: 20px;
}

@media (min-width: 578px) and (max-width: 992px) {
    #system-card {
        max-width: 80vw;
        padding: 16px !important;
        flex-direction: row;
        align-items: center;
    }
    .system-card-image {
        width: 42vw;
        height: 35vw;
    }
    .system-card-description {
        height: auto;
        font-size: 2vw;
    }
}

@media (max-width: 576px) {
    #system-card {
        padding: 24px;
    }
    #system-card .card-body {
        margin-bottom: 0 !important;
    }
    .system-card-description {
        height: auto;
    }
}
