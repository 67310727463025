#definition {
    position: relative;
}
#definition .container-fluid {
    padding: min(4.05vw, 80px) 0;
    position: relative;
    z-index: -1;
}
.definition-block {
    justify-content: center;
    margin: 0;
}
.definition-block .col {
    max-width: min(90vw, 1300px);
}

.system-cards-block {
    justify-content: center;
    margin: 0 !important;
    position: relative;
    z-index: 3;
}
.system-cards-container {
    padding: 0;
    max-width: min(90vw, 1515px);
    display: flex;
    gap: min(1.8vw, 35px);
}

.system-cards-block-background {
    margin: 0;
    width: 100%;
    height: min(28.5vw, 470px);
    background-color: #9cd8e9 !important;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
}

@media (max-width: 992px) {
    .system-cards-container {
        display: block;
        max-width: 90vw;
    }
    .definition-block .fs-3 {
        font-size: 1.2rem !important;
    }
    .system-cards-container .col {
        display: flex;
        justify-content: center;
    }
    .system-cards-block-background {
        display: none;
    }
}
