.circle {
    width: 10vw;
    height: 10vw;
    max-width: 141px;
    max-height: 141px;
    background-color: #9cd8e9;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 10px auto;
    font-size: clamp(24px, 6vw, 96px);
    color: #000;
}

.values-row .col {
    max-width: min(92vw, 1535px) !important;
}

.jedi-card .card-body {
    padding: 0;
}

.jedi-card .row {
    margin: 0;
    padding: 20px;
}

.jedi-card {
    background-color: inherit !important;
    border: none;
}

.jedi-card p {
    text-align: left !important;
}

@media (max-width: 992px) {
    .jedi-card .row {
        flex-direction: row;
        align-items: center;
    }

    .jedi-card .col-xs-4,
    .jedi-card .col-xs-8 {
        width: auto;
    }

    .jedi-card .circle {
        margin-bottom: 0;
    }

    .jedi-card .card-title {
        margin-top: 0;
        text-align: center;
        margin-right: 0rem;
    }

    .jedi-card .card-text {
        text-align: left;
    }
}

.italic {
    font-style: italic;
}

.no-wrap {
    white-space: nowrap;
}

@media (max-width: 350px) {
    .jedi-card .row {
        padding: 20px 0 !important;
    }
}
