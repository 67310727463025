#acknowledgments {
    background-color: #ffffff;
    padding: 3rem 0;
}

#acknowledgments h2 {
    margin-bottom: 1.5rem;
}

#acknowledgments p {
    font-size: 1.25rem;
}

#acknowledgments img {
    max-width: 100%;
    height: auto;
    margin: 1rem auto;
}

@media (max-width: 576px) {
    #acknowledgments p {
        font-size: 1rem;
    }
}
