.leaders-card-container img {
    margin: 0 auto;
    border: 6px solid #021647;
}
.leaders-col {
    padding-bottom: 0 !important;
    display: flex;
    justify-content: center;
}

@media (max-width: 1200px) {
    .leaders-card-container img {
        width: 200px !important;
        height: 200px !important;
        border: 3px solid #021647;
    }
}

/* Small devices */
@media (max-width: 576px) {
    .leaders-card-container img {
        width: 35vw !important;
        height: 35vw !important;
        border: 1px solid #021647;
    }
    #leaders-card .card-body > * {
        display: block;
        justify-content: center;
        margin: 0 auto !important;
        font-size: 1.2rem;
    }
    #leaders-card .h3 {
        font-size: 1.25rem;
    }
}
