.event-card {
    border-radius: 20px;
}
.event-card .card-body {
    border-radius: 20px;
}

.event-card .odd {
    color: #fff;
    background-color: #003738;
}
.event-card .even {
    color: #1e1e1e;
    border-left-color: black !important;
    background-color: #9cd8e9;
}
.event-card button {
    border: none !important;
}

@media (min-width: 768px) {
    .event-card .border-start {
        border: none !important;
    }
}
