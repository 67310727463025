#contactus {
    background-color: #003738;
}
#contactus .form-container {
    padding: 80px 0;
    padding: min(6vw, 80px) 0;
    max-width: 1920px;
    margin: 0 auto;
}
#contactus form {
    border-radius: 32px;
    background-color: #fff;
    display: flex;
    max-width: 741px;
    padding: 40px min(5vw, 48px); /* padding: 40px 48px;*/
    flex-direction: column;
    align-self: stretch;
    margin: auto;
}

#contactus .form-header .form-title {
    font-size: min(calc(1.6rem + 5.5vw), 60px);
    line-height: 39px;
    margin-bottom: 24px !important;
}
.form-container .input-group,
.form-comment .form-control {
    height: 90px;
    border-radius: 20px;
    padding: 0 32px 0;
    border-radius: 20px;
    border: 1px solid #afafaf;
    background: #fff;
}
.form-header .form-description {
    color: #6d6d6d;
    line-height: normal;
}

.input-log-label img,
#basic-addon1 {
    margin: auto;
    width: 42px;
    height: 42px;
    border: none !important;
}
#basic-input,
#floatingInput,
.input-log-label .form-floating {
    margin: auto 0;
    border: none !important;
}

.input-log-label .form-control:focus {
    box-shadow: none;
    outline: none;
    border-color: transparent;
}

.form-comment .form-control:focus {
    box-shadow: none;
}
.form-send-btn {
    color: #fff !important;
    font-weight: bolder;
    background-color: #00696c;
    padding: 16px 32px;
    height: 64px;
}
.form-send-btn:hover {
    background-color: #1f8c8f;
}

@media (max-width: 992px) {
    #contactus {
        padding-left: 6vw;
        padding-right: 6vw;
    }
}
