// Import Google Font
@import url('https://fonts.googleapis.com/css2?family=Familjen+Grotesk:wght@400;700&display=swap');

// Override Bootstrap variables
$primary: #003738;
$secondary: #55bfef;
$warning: #f9c416; // tertiary
$danger: #bc4622; // quaternary
$light: #f0f0f0; // background- lightest
$dark: #080808; // background- darkest

// Need to create guidance around the following variables
// $success: #28a745;
// $info: #17a2b8;

// Set custom font
$font-family-base: 'Familjen Grotesk', sans-serif;

// Custom button styles
$btn-border-radius: 360px;
$btn-backgd-color: #f9c416;
$btn-hover-backgd-color: #ffdd6e;
$btn-transition: opacity 0.5s ease;
$btn-hover-opacity: 0.8;
$btn-font-color: #404040;
$btn-font-weight: 600;

$enable-negative-margins: true;

// Import Bootstrap
@import '~bootstrap/scss/bootstrap';

// Additional button customization
.btn {
    border: none;
    border-radius: $btn-border-radius;
    background-color: $btn-backgd-color;
    font-family: $font-family-base;
    color: $btn-font-color;
    font-weight: $btn-font-weight;
    transition: $btn-transition;

    &:hover {
        // opacity: $btn-hover-opacity;
        color: $btn-font-color;
        background-color: $btn-hover-backgd-color;
        color: $primary;
    }
}

// https://getbootstrap.com/docs/5.0/content/typography/
// https://mdbootstrap.com/docs/react/utilities/sizing/
html,
body,
ul,
ol {
    margin: 0;
    padding: 0;
}

:root {
    --font-color-primary: #{$primary};
}
