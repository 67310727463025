.project-row {
    justify-content: space-between;
    max-width: 1185px;
    margin: 0 auto;
}
.projectUpdates-title {
    color: #000d31;
}

@media (min-width: 992px) and (max-width: 1200px) {
    .project-row {
        justify-content: space-around;
        max-width: 95vw;
    }
}
@media (max-width: 992px) {
    .project-row {
        padding: 0 5vw;
    }
}
