#mission {
    background-color: #f0f0f0;
    padding: 3rem 0;
}

.mission-heading {
    margin-bottom: 1.5rem;
}

.mission-subheading {
    margin-bottom: .3rem;
}

.mission-item {
    border: none !important;
    border-radius: 20px !important;
    background-color: #ffffff;
    min-height: 145px;
    padding: 1rem;
}

.mission-icon {
    width: 50px;
    height: 50px;
}

@media (min-width: 1920px) {
    .mission-container {
        max-width: 1680px;
    }
    .mission-heading-container {
        padding-left: 120px;
        padding-right: 120px;
    }
}
